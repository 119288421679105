<template>
  <div
    v-if="
      (hasStock(stock) || hasToCheckAvailability === false) && hasToShowProduct
    "
    :class="classSelector"
    @click="
      canRedirectToProduct && hasStock(stock) && isProductAvailable
        ? pushProductRoute(uuid)
        : ''
    "
  >
    <article class="prod-container">
      <section class="prod-left-info">
        <section class="prod-left-info-up">
          <h6 class="text-h3 font-weight-medium mb-1 wrap-text">
            {{ name }}
          </h6>
          <p
            class="text-body-2 accent--text mb-0 py-1 clamp-in-2"
            ref="description"
          >
            {{ description }}
          </p>
          <p @click="toggleDescription" v-if="isOverflowing" class="show-hide">
            {{ linkText }}
          </p>
        </section>
        <section class="prod-left-info-down">
          <section class="prod-left-info-inner-down">
            <p class="text-justify text-h3 font-weight-bold mb-0">
              {{ `${currencySymbol()} ${formatPrice(price, 2)}` }}
            </p>
            <img
              class="product-preview-image"
              :src="hasSecondaryPreviewImage ? secondaryPreviewImage : ''"
            />
          </section>
        </section>
      </section>
      <section class="prod-right-info">
        <base-img
          v-if="image"
          :aspect-ratio="1"
          class="rounded-md prod-image"
          :src="image"
          cover
          position="center"
          max-width="12rem"
        />
        <base-img
          v-else
          :aspect-ratio="1"
          :src="''"
          class="rounded-lg prod-image"
          max-width="12rem"
        />
      </section>
    </article>
    <div v-if="hasToShowArcaDisclaimerOfTaxes" class="arca-disclaimer-of-taxes">
      SIN IMPUESTOS NACIONALES:
      {{ `${currencySymbol()} ${formatPrice(getNetAmount(), 2)}` }}
    </div>
    <div class="unavailable" v-if="!isProductAvailable">
      <p class="text-body-2 accent--text mb-0 py-1">No disponible</p>
    </div>
  </div>
</template>

<script>
import { formatPrice, currencySymbol } from "@/utils/price.js";
import stock from "@/mixins/stock";
import { getCountryCode } from "../utils/country";

export default {
  mixins: [stock],
  props: {
    uuid: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    price: {
      type: [String, Number],
      default: null,
    },
    image: {
      type: String,
      default: "",
    },
    secondaryPreviewImage: {
      type: String,
      default: null,
    },
    stock: {
      type: [String, Number],
      default: null,
    },
    canRedirectToProduct: {
      type: Boolean,
      default: false,
    },
    isProductAvailable: {
      type: Boolean,
      default: true,
    },
    vatRate: {
      type: [String, Number],
      default: 0,
    },
    isExempt: {
      type: [Boolean],
      default: false,
    },
    isNonTaxed: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      isOverflowing: false,
      isExpanded: false,
      linkText: "Mostrar más",
    };
  },
  mounted() {
    this.checkOverflow();
  },
  computed: {
    hasToShowArcaDisclaimerOfTaxes() {
      if (getCountryCode() === "AR") {
        return true;
      } else return false;
    },
    hasSecondaryPreviewImage() {
      return this.secondaryPreviewImage?.length > 0;
    },
    classSelector() {
      const { menuUUID, originName } = this.$route.query;
      if (menuUUID || originName)
        return "prod-main-container main-box-can-redirect product-container";
      else
        return {
          "prod-main-container main-box-can-redirect product-container":
            this.canRedirectToProduct && this.hasStock(this.stock),
          disabled: !this.isProductAvailable,
        };
    },
    hasToShowUnavailableProducts() {
      return (
        this.$store.state.menu.profile.hasToShowUnavailableProducts ?? false
      );
    },
    hasToShowProduct() {
      if (this.isProductAvailable) return true;
      else if (this.hasToShowUnavailableProducts) return true;
      return false;
    },
  },
  methods: {
    formatPrice,
    currencySymbol,
    getNetAmount() {
      console.log(this.price, this.vatRate, this.isExempt, this.isNonTaxed);
      if (this.isExempt || this.isNonTaxed) {
        return this.price;
      } else {
        return this.price * (1 - this.vatRate / 100);
      }
    },
    pushProductRoute(uuid) {
      this.$router.push({
        name: "Product",
        params: { productId: uuid },
        query: this.$route.query,
      });
    },
    checkOverflow() {
      if (!this.canRedirectToProduct) {
        const descriptionElement = this.$refs.description;
        this.isOverflowing =
          descriptionElement.scrollHeight > descriptionElement.clientHeight;
      }
    },
    toggleDescription() {
      this.isExpanded = !this.isExpanded;
      const descriptionElement = this.$refs.description;

      if (this.isExpanded) {
        descriptionElement.style.display = "block";
        descriptionElement.style.webkitLineClamp = "unset";
        this.linkText = "Mostrar menos";
      } else {
        descriptionElement.style.webkitLineClamp = "2";
        descriptionElement.style.display = "-webkit-box";
        this.linkText = "Mostrar más";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-text {
  overflow-wrap: break-word;
}

.card-border {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.main-box-can-redirect:hover {
  cursor: pointer;
  background-color: rgba(232, 232, 232, 0.5);
}

.without-stock {
  color: rgb(255, 0, 0);
}

.product-card-right {
  // min-height: 155px;
}

.product-card-left {
  // min-height: 173px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-preview-image {
  max-height: 35px;
  border-radius: 8px 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.product-preview-image-container {
  display: flex;
  padding: 0px;
  justify-content: flex-end;
}

//Cuando el producto no esta disponible se activan estas clases
.disabled,
.disabled:hover {
  background-color: #d7d7d7;
  cursor: default;
}

.rounded-md {
  border-radius: 4px;
}

.clamp-in-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* Show only 2 lines */
  overflow: hidden;
  line-height: unset;
  text-overflow: ellipsis;
}
.show-hide {
  text-decoration: underline;
  font-size: 13px;
}
.prod-container {
  margin: 14px 0px;
  display: flex;
  justify-content: center;
}

.prod-left-info {
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 15px;
}

.prod-right-info {
  width: 33%;
}

.prod-right-info .prod-image {
  max-width: 120px;
}

.prod-left-info-down {
  display: flex;
  flex-direction: column;
}

.prod-left-info-inner-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.prod-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 14px;
}

.unavailable {
  width: 90%;
}

.product-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.arca-disclaimer-of-taxes {
  font-size: 12px;
}
</style>
